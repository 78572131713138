/* tslint:disable */
/* eslint-disable */
/**
 * Unleash API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.10.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConstraintSchema,
    ConstraintSchemaFromJSON,
    ConstraintSchemaFromJSONTyped,
    ConstraintSchemaToJSON,
} from './ConstraintSchema';

/**
 * 
 * @export
 * @interface StrategySchema
 */
export interface StrategySchema {
    /**
     * 
     * @type {string}
     * @memberof StrategySchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StrategySchema
     */
    name: string;
    /**
     * 
     * @type {Array<ConstraintSchema>}
     * @memberof StrategySchema
     */
    constraints: Array<ConstraintSchema>;
    /**
     * 
     * @type {object}
     * @memberof StrategySchema
     */
    parameters: object;
}

export function StrategySchemaFromJSON(json: any): StrategySchema {
    return StrategySchemaFromJSONTyped(json, false);
}

export function StrategySchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrategySchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'constraints': ((json['constraints'] as Array<any>).map(ConstraintSchemaFromJSON)),
        'parameters': json['parameters'],
    };
}

export function StrategySchemaToJSON(value?: StrategySchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'constraints': ((value.constraints as Array<any>).map(ConstraintSchemaToJSON)),
        'parameters': value.parameters,
    };
}

