/* tslint:disable */
/* eslint-disable */
/**
 * Unleash API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.10.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeProjectSchema,
    ChangeProjectSchemaFromJSON,
    ChangeProjectSchemaToJSON,
    CreateFeatureSchema,
    CreateFeatureSchemaFromJSON,
    CreateFeatureSchemaToJSON,
    FeatureSchema,
    FeatureSchemaFromJSON,
    FeatureSchemaToJSON,
    FeaturesSchema,
    FeaturesSchemaFromJSON,
    FeaturesSchemaToJSON,
} from '../models';

export interface ApiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPostRequest {
    projectId: string;
    featureName: string;
    changeProjectSchema: ChangeProjectSchema;
}

export interface ApiAdminProjectsProjectIdFeaturesFeatureNameGetRequest {
    projectId: string;
    featureName: string;
}

export interface ApiAdminProjectsProjectIdFeaturesGetRequest {
    projectId: string;
}

export interface ApiAdminProjectsProjectIdFeaturesPostRequest {
    projectId: string;
    createFeatureSchema: CreateFeatureSchema;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminArchiveFeaturesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeaturesSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/archive/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeaturesSchemaFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminArchiveFeaturesGet(initOverrides?: RequestInit): Promise<FeaturesSchema> {
        const response = await this.apiAdminArchiveFeaturesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminFeaturesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeaturesSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/features/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeaturesSchemaFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminFeaturesGet(initOverrides?: RequestInit): Promise<FeaturesSchema> {
        const response = await this.apiAdminFeaturesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPostRaw(requestParameters: ApiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPost.');
        }

        if (requestParameters.featureName === null || requestParameters.featureName === undefined) {
            throw new runtime.RequiredError('featureName','Required parameter requestParameters.featureName was null or undefined when calling apiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPost.');
        }

        if (requestParameters.changeProjectSchema === null || requestParameters.changeProjectSchema === undefined) {
            throw new runtime.RequiredError('changeProjectSchema','Required parameter requestParameters.changeProjectSchema was null or undefined when calling apiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/projects/{projectId}/features/{featureName}/changeProject`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"featureName"}}`, encodeURIComponent(String(requestParameters.featureName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeProjectSchemaToJSON(requestParameters.changeProjectSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPost(requestParameters: ApiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAdminProjectsProjectIdFeaturesFeatureNameChangeProjectPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesFeatureNameGetRaw(requestParameters: ApiAdminProjectsProjectIdFeaturesFeatureNameGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureSchema>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiAdminProjectsProjectIdFeaturesFeatureNameGet.');
        }

        if (requestParameters.featureName === null || requestParameters.featureName === undefined) {
            throw new runtime.RequiredError('featureName','Required parameter requestParameters.featureName was null or undefined when calling apiAdminProjectsProjectIdFeaturesFeatureNameGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/projects/{projectId}/features/{featureName}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"featureName"}}`, encodeURIComponent(String(requestParameters.featureName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureSchemaFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesFeatureNameGet(requestParameters: ApiAdminProjectsProjectIdFeaturesFeatureNameGetRequest, initOverrides?: RequestInit): Promise<FeatureSchema> {
        const response = await this.apiAdminProjectsProjectIdFeaturesFeatureNameGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesGetRaw(requestParameters: ApiAdminProjectsProjectIdFeaturesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeaturesSchema>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiAdminProjectsProjectIdFeaturesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/projects/{projectId}/features`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeaturesSchemaFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesGet(requestParameters: ApiAdminProjectsProjectIdFeaturesGetRequest, initOverrides?: RequestInit): Promise<FeaturesSchema> {
        const response = await this.apiAdminProjectsProjectIdFeaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesPostRaw(requestParameters: ApiAdminProjectsProjectIdFeaturesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureSchema>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiAdminProjectsProjectIdFeaturesPost.');
        }

        if (requestParameters.createFeatureSchema === null || requestParameters.createFeatureSchema === undefined) {
            throw new runtime.RequiredError('createFeatureSchema','Required parameter requestParameters.createFeatureSchema was null or undefined when calling apiAdminProjectsProjectIdFeaturesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // apiKey authentication
        }

        const response = await this.request({
            path: `/api/admin/projects/{projectId}/features`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFeatureSchemaToJSON(requestParameters.createFeatureSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureSchemaFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminProjectsProjectIdFeaturesPost(requestParameters: ApiAdminProjectsProjectIdFeaturesPostRequest, initOverrides?: RequestInit): Promise<FeatureSchema> {
        const response = await this.apiAdminProjectsProjectIdFeaturesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
