/* tslint:disable */
/* eslint-disable */
/**
 * Unleash API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.10.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OverrideSchema,
    OverrideSchemaFromJSON,
    OverrideSchemaFromJSONTyped,
    OverrideSchemaToJSON,
} from './OverrideSchema';

/**
 * 
 * @export
 * @interface VariantSchema
 */
export interface VariantSchema {
    /**
     * 
     * @type {string}
     * @memberof VariantSchema
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof VariantSchema
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof VariantSchema
     */
    weightType: string;
    /**
     * 
     * @type {string}
     * @memberof VariantSchema
     */
    stickiness: string;
    /**
     * 
     * @type {object}
     * @memberof VariantSchema
     */
    payload?: object;
    /**
     * 
     * @type {Array<OverrideSchema>}
     * @memberof VariantSchema
     */
    overrides?: Array<OverrideSchema>;
}

export function VariantSchemaFromJSON(json: any): VariantSchema {
    return VariantSchemaFromJSONTyped(json, false);
}

export function VariantSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'weight': json['weight'],
        'weightType': json['weightType'],
        'stickiness': json['stickiness'],
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
        'overrides': !exists(json, 'overrides') ? undefined : ((json['overrides'] as Array<any>).map(OverrideSchemaFromJSON)),
    };
}

export function VariantSchemaToJSON(value?: VariantSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'weight': value.weight,
        'weightType': value.weightType,
        'stickiness': value.stickiness,
        'payload': value.payload,
        'overrides': value.overrides === undefined ? undefined : ((value.overrides as Array<any>).map(OverrideSchemaToJSON)),
    };
}

