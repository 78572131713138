import { IFeatureToggleListItem } from 'interfaces/featureToggle';

const loadingFeatures: IFeatureToggleListItem[] = [
    {
        type: 'release',
        name: 'loading1',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'loadg2',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'loading3',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'loadi4',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'loadi5',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'loadg6',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'loading7',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'ln8',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
    {
        type: 'release',
        name: 'load9',
        createdAt: '2006-01-02T15:04:05Z',
        environments: [
            {
                name: ':global:',
                enabled: true,
            },
        ],
    },
];

export default loadingFeatures;
